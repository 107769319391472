.skeleton-medium {
    width: 400px;
    height: 101%;
    margin: 0;
    position: relative;
    top: -2px;
}
.skeleton-short {
    width: 180px;
    height: 101%;
    margin: 0;
    position: relative;
    top: -1px;
    aspect-ratio: 1/1;
}

@media (max-width: 1440px) {
    .skeleton-medium {
        width: 300px;
    }
    .skeleton-short {
        width: 140px;
    }
} 

@media (max-width: 1200px) {
    .skeleton-medium {
        width: 210px;
    }
    .skeleton-short {
        width: 120px;
    }
    .skeleton-short-title {
        width: 120px;
        height: 25px;
    }
}

@media (max-width: 1024px) {
    .skeleton-medium {
        width: 220px;
    }
    .skeleton-short {
        width: 124px;
    }
} 

@media (max-width: 992px) {
    .skeleton-medium {
        width: 100%;
        aspect-ratio: 1/1;
    }
}
